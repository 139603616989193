import { httpSnackbar, httpSilent } from '@/plugins/axios'

export default class SuporteWebClient {
  http = httpSnackbar;

  /**
   * Construtor
   * @param {boolean} silent
   */
  constructor(silent = false) {
    if (silent) this.http = httpSilent;
  }

  /**
   * Obtem os dados de um chamado, incluindo os seus comentários.
   * @param {number} id Chave primaria do chamado.
   * @returns {Promise<any>}
   */
  async getChamado(id) {
    const {data} = await this.http.get('/suporte/chamados', {params: {id: id.toString()}});
    return data;
  }

  /**
   * Obtem a listagem de chamado do usuario atualmente logado, ou de todos.
   * @param {boolean} todos Exibe chamados de todos os usuarios.
   * @returns {Promise<any[]>}
   */
  async getChamados(todos = false) {
    const params = {
      'todos': todos ? 'S' : undefined,
    };
    const {data} = await this.http.get('/suporte/chamados', {params});
    return data;
  }

  /**
   * Insere um chamado.
   * @param {string} titulo Assunto do chamado.
   * @param {string} descricao Detalhes do chamado.
   * @param {0|1|2} prioridade 0=Normal, 1=U, 2=UU.
   * @returns {Promise<number>}
   */
  async insertChamado(titulo, descricao, prioridade) {
    const formData = new FormData();
    formData.append('titulo', titulo);
    formData.append('descricao', descricao);
    formData.append('prioridade', prioridade.toString());
    const {data} = await this.http.post('/suporte/chamados', formData);
    return data;
  }

  /**
   * Altera o status de um chamado.
   * @param {number} chamadoId Chave primaria do chamado.
   * @param {number} novoStatus Numero do status.
   * @returns {Promise<void>}
   */
  async updateChamadoStatus(chamadoId, novoStatus) {
    const formData = new FormData();
    formData.append('id', chamadoId.toString());
    formData.append('status', novoStatus.toString());
    await this.http.put('/suporte/chamados', formData);
  }

  /**
   * Insere um comentário em um chamado.
   * @param {number} chamadoId Chave primaria do chamado.
   * @param {string} texto Texto do comentario.
   * @returns {Promise<any>}
   */
  async insertChamadoComentario(chamadoId, texto) {
    const formData = new FormData();
    formData.append('chamado', chamadoId.toString());
    formData.append('texto', texto);
    const {data} = await this.http.post('/suporte/comentario', formData);
    return data;
  }
}