<template>
  <v-container class="d-flex justify-center">
    <v-alert
        type="error"
    >A página que procura não existe</v-alert>
  </v-container>
</template>

<script>
export default {
  name: 'Error'
}
</script>

<style scoped>

</style>