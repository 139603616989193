<template>
  <async-container fluid :loading="!loaded">
    <v-data-table
      :headers="headers"
      :items="somenteEmAndamento ? items.filter(i => i.status === 0) : items"
      :class="{'elevation-3': !outlined}"
      :sort-by="['status', 'criacao']"
      :sort-desc="[false, true]"
      multi-sort
      :footer-props="{'items-per-page-options': [10, 25, 50, 100]}"
      :no-data-text="somenteEmAndamento ? 'Nenhum chamado em andamento' : 'Nenhum chamado no momento'"
    >
      <template v-slot:top>
        <v-card-title class="title-bar" v-if="!hideHeader">
          {{titulo}}
          <v-spacer/>
          <v-btn color="primary" to="/suporte/chamadoeditor">CRIAR</v-btn>
        </v-card-title>
      </template>
      <template v-slot:item.titulo="{item}">
        <v-btn text :to="'/suporte/chamado/' + item.id" small color="primary">{{item.titulo}}</v-btn>
        <v-chip small v-if="item.prioridade === 1" label color="error">U</v-chip>
        <v-chip small v-else-if="item.prioridade === 2" label color="error">UU</v-chip>
      </template>
      <template v-slot:item.criacao="{item}">
        {{formatarData(item.criacao)}}
      </template>
      <template v-slot:item.status="{item}">
        <v-chip label small v-if="item.status === 0" color="primary">EM ANDAMENTO</v-chip>
        <v-chip label small v-else-if="item.status === 1" color="success">ENCERRADO</v-chip>
        <v-chip label small v-else-if="item.status === 2">ARQUIVADO</v-chip>
      </template>
    </v-data-table>
  </async-container>
</template>

<script>
import AsyncContainer from '@/components/interface/AsyncContainer';
import SuporteWebClient from '@/http/SuporteWebClient';
import moment from 'moment';
export default {
  name: 'Chamados',
  components: {AsyncContainer},
  props: {
    somenteEmAndamento: {
      type: Boolean,
      default: false,
    },
    titulo: {
      type: String,
      default: 'Meus chamados',
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    hideHeader: {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    headers: [
      {text: 'Protocolo', value: 'id'},
      {text: 'Assunto', value: 'titulo'},
      {text: 'Data', value: 'criacao', align: 'center'},
      {text: 'Status', value: 'status', align: 'center'},
    ],
    items: [],
    loaded: false,
    webClient: new SuporteWebClient(),
  }),
  methods: {
    async loadData() {
      try {
        this.items = await this.webClient.getChamados();
      } catch (e) {
        this.$router.push('/suporte');
      } finally {
        this.loaded = true;
      }
    },
    formatarData(datetime) {
      return moment(datetime).format('DD/MM/YYYY HH:mm');
    },
  },
  created() {
    this.loadData();
  },
}
</script>

<style scoped>
  .title-bar {
    background-color: #f5f5f5;
    border-bottom: 1px solid #dbdbdb !important;
    padding: .75rem;
    margin-bottom: 0;
  }
</style>