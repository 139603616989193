import { httpSnackbar, httpSilent } from '@/plugins/axios'

export default class SiteWebClient {
  http = httpSnackbar

  /**
   * Construtor
   * @param {boolean} silent
   */
  constructor(silent = false) {
    if (silent) this.http = httpSilent;
  }

  //=== Noticias ===//

  /**
   * Obtem as noticias do site do CRECI.
   * @returns {Promise<any[]>}
   */
  async getNoticias() {
    const {data} = await this.http.get('/sitecreci/noticia');
    return data;
  }

  /**
   * Obtem os dados de uma noticia
   * @param {number} id Chave primaria
   * @returns {Promise<any>}
   */
  async getNoticia(id) {
    const {data} = await this.http.get('/sitecreci/noticia', {params: {id: id.toString()}});
    return data;
  }

  /**
   * Insere uma noticia. Obtem seu ID.
   * @param {string} titulo Titulo da noticia.
   * @param {string} texto Corpo da noticia.
   * @param {Blob} blob_capa Imagem de capa em JPEG 1000x540.
   * @param {Blob} blob_miniatura Imagem miniatura em JPEG 96x96.
   * @param {string} datetime_divulgacao Data da publicação.
   * @param {null|string} datetime_remocao Data para remoção automatica.
   * @param {Blob[]} fotos Imagens para adicionar ao album da noticia.
   * @returns {Promise<any>} ID da noticia.
   */
  async insertNoticia(titulo, texto, blob_capa, blob_miniatura, datetime_divulgacao, datetime_remocao = null, fotos = []) {
    const formData = new FormData();
    formData.append('titulo', titulo);
    formData.append('artigo', texto);
    formData.append('capa', blob_capa);
    formData.append('miniatura', blob_miniatura);
    formData.append('divulgacao', datetime_divulgacao);
    if (datetime_remocao) formData.append('remocao', datetime_remocao);
    fotos.forEach((file, index) => {
      formData.append(index.toString(), file);
    });
    const {data} = await this.http.post('/sitecreci/noticia', formData);
    return data;
  }

  /**
   * Atualiza uma noticia.
   * @param {number} id Chave primaria.
   * @param {string} titulo Titulo da publicacao.
   * @param {string} texto Texto da publicacao.
   * @param {Blob} blob_capa Imagem de capa JPEG 1000x540.
   * @param {Blob} blob_miniatura Image de miniatura JPEG 96x96.
   * @param {string} datetime_divulgacao Data da publicacao.
   * @param {null|string} datetime_remocao Data da remocao.
   * @param {Blob[]} fotos Imagens para adicionar ao album da noticia, somente adiciona, nao remove as existentes.
   * @returns {Promise<any>} ID da noticia.
   */
  async updateNoticia(id, titulo, texto, blob_capa, blob_miniatura, datetime_divulgacao, datetime_remocao = null, fotos = []) {
    const formData = new FormData();
    formData.append('id', id.toString());
    formData.append('titulo', titulo);
    formData.append('artigo', texto);
    formData.append('capa', blob_capa);
    formData.append('miniatura', blob_miniatura);
    formData.append('divulgacao', datetime_divulgacao);
    if (datetime_remocao) formData.append('remocao', datetime_remocao);
    fotos.forEach((file, index) => {
      formData.append(index.toString(), file);
    });
    const {data} = await this.http.post('/sitecreci/noticia', formData);
    return data;
  }

  /**
   * Apaga uma noticia.
   * @param {number} id Chave primaria
   * @returns {Promise<void>}
   */
  async deleteNoticia(id) {
    await this.http.delete('/sitecreci/noticia', {params: {id: id.toString()}});
  }

  /**
   * Obtem a imagem da capa
   * @param noticiaId Chave primaria
   * @returns {Promise<Blob|boolean>} False = Erro, Null = não possui foto.
   */
  async getCapa(noticiaId) {
    const response = await this.http.get('/sitecreci/noticia/capa', {
      params: {id: noticiaId.toString()},
      responseType: 'blob',
    });
    if (response.status === 200) return response.data;
    else if (response.status === 404) return null;
    else return false;
  }

  /**
   * Obtem a imagem de miniatura da capa
   * @param noticiaId Chave primaria
   * @returns {Promise<Blob|boolean>} False = Erro, Null = não possui foto.
   */
  async getMiniatura(noticiaId) {
    const response = await this.http.get('/sitecreci/noticia/miniatura', {
      params: {id: noticiaId.toString()},
      responseType: 'blob',
    });
    if (response.status === 200) return response.data;
    else if (response.status === 404) return null;
    else return false;
  }

  async getNoticiaImagem(id) {
    const response = await this.http.get('/sitecreci/noticia/galeria', {
      params: {id: id.toString()},
      responseType: 'blob',
    });
    if (response.status === 200) return response.data;
    else if (response.status === 404) return null;
    else return false;
  }

  getNoticiaImagemUrl(id) {
    return this.http.defaults.baseURL + '/sitecreci/noticia/galeria?id=' + id;
  }

  async deleteNoticiaImagem(id) {
    await this.http.delete('/sitecreci/noticia/galeria', {params: {id: id.toString()}});
  }

  //=== Destaques ===//

  /**
   * Obtem um array com os dados dos destaques (banners).
   * @returns {Promise<any[]>}
   */
  async getDestaques() {
    const {data} = await this.http.get('/sitecreci/destaque');
    return data;
  }

  /**
   * Obtem os dados de um destaque, inclusive o Blob de sua imagem.
   * @param {number} id Chave primaria.
   * @returns {Promise<any>}
   */
  async getDestaque(id) {
    const destaques = await this.getDestaques();
    const destaque = destaques.find(i => i.id === id);
    destaque.imagem = await this.getDestaqueImage(id);
    return destaque;
  }

  /**
   * Obtem a imagem (o banner) do destaque.
   * @param destaqueId Chave primaria
   * @returns {Promise<null|boolean|Blob>} Blob da imagem JPEG. Null = Não possui imagem. False = Erro ao obter.
   */
  async getDestaqueImage(destaqueId) {
    const response = await this.http.get('/sitecreci/destaque', {
      params: {id: destaqueId.toString()},
      responseType: 'blob',
    });
    if (response.status === 200) return response.data;
    else if (response.status === 404) return null;
    else return false;
  }

  /**
   * Insere um destaque.
   * @param {Blob} imagem Blob de uma imagem JPEG 750x405.
   * @param {string} link
   * @param {string} titulo
   * @param {string} legenda
   * @returns {Promise<any>}
   */
  async insertDestaque(imagem, link = '', titulo = '', legenda = '') {
    const formData = new FormData();
    formData.append('imagem', imagem);
    if (link) formData.append('link', link);
    if (titulo) formData.append('titulo', titulo);
    if (legenda) formData.append('legenda', legenda);
    const {data} = await this.http.post('/sitecreci/destaque', formData);
    return data;
  }

  /**
   * Atualiza a sequencia dos destaques
   * @param {array<{id: Number, ordem: Number}>} destaques
   * @returns {Promise<any>}
   */
  async updateDestaquesOrdem(destaques) {
    const {data} = await this.http.put('/sitecreci/destaque', destaques);
    return data;
  }

  /**
   * Atualiza um destaque.
   * @param {number} id
   * @param {Blob} imagem Blob de uma imagem JPEG 750x405.
   * @param {string} link
   * @param {string} titulo
   * @param {string} legenda
   * @returns {Promise<void>}
   */
  async updateDestaque(id, imagem, link = '', titulo = '', legenda = '') {
    const formData = new FormData();
    formData.append('id', id.toString());
    formData.append('imagem', imagem);
    if (link) formData.append('link', link);
    if (titulo) formData.append('titulo', titulo);
    if (legenda) formData.append('legenda', legenda);
    await this.http.post('/sitecreci/destaque', formData);
  }

  /**
   * Apaga um destaque.
   * @param {number} id Chave primaria.
   * @returns {Promise<void>}
   */
  async deleteDestaque(id) {
    await this.http.delete('/sitecreci/destaque', {params: {id: id.toString()}});
  }

  //=== Comunicados ===//

  /**
   * Obtem os comunidados.
   * @returns {Promise<any[]>}
   */
  async getComunicados() {
    const {data} = await this.http.get('/sitecreci/comunicado');
    return data;
  }

  /**
   * Obtem um comunicado (incluindo o blob da imagem).
   * @param {number} id Chave primaria
   * @returns {Promise<any>}
   */
  async getComunicado(id) {
    const {data} = await this.http.get('/sitecreci/comunicado', {params: {id: id.toString()}});
    data.imagem = await this.getComunicadoImagem(id);
    return data;
  }

  /**
   * Obtem o Blob da imagem JPEG do comunicado.
   * @param id Chave primaria.
   * @returns {Promise<null|boolean|Blob>} False = Erro. Null = Não encontrado.
   */
  async getComunicadoImagem(id) {
    const response = await this.http.get('/sitecreci/comunicado/imagem', {
      params: {id: id.toString()},
      responseType: 'blob',
    });
    if (response.status === 200) return response.data;
    else if (response.status === 404) return null;
    else return false;
  }

  /**
   * Insere um comunicado.
   * @param {string} titulo Titulo do comunicado.
   * @param {number} largura Nível de largura máxima do modal.
   * @param {string} inicio Datetime da publicação.
   * @param {string} fim Datetime da remoção automática.
   * @param {Blob} imagem Blob da imagem JPEG.
   * @param {string} texto Texto do comunicado.
   * @param {string} botao Texto do botão de ação do modal. Vazio = sem botão.
   * @param {string} link Link do botão de ação do modal.
   * @returns {Promise<void>}
   */
  async insertComunicado(titulo, largura, inicio, fim, imagem = null, texto ='', botao = '', link = '') {
    const formData = new FormData();
    formData.append('titulo', titulo);
    formData.append('largura', largura.toString());
    formData.append('inicio', inicio);
    formData.append('fim', fim);
    if (imagem) formData.append('imagem', imagem);
    if (texto) formData.append('texto', texto);
    if (botao) formData.append('botao', botao);
    if (link) formData.append('link', link);
    await this.http.post('/sitecreci/comunicado', formData);
  }

  /**
   * Atualiza um comunicado.
   * @param {number} id Chave primaria.
   * @param {string} titulo Titulo do comunicado.
   * @param {number} largura Nível de largura máxima do modal.
   * @param {string} inicio Datetime da publicação.
   * @param {string} fim Datetime da remoção automática.
   * @param {Blob} imagem Blob da imagem JPEG.
   * @param {string} texto Texto do comunicado.
   * @param {string} botao Texto do botão de ação do modal. Vazio = sem botão.
   * @param {string} link Link do botão de ação do modal.
   * @returns {Promise<void>}
   */
  async updateComunicado(id, titulo, largura, inicio, fim, imagem = null, texto ='', botao = '', link = '') {
    const formData = new FormData();
    formData.append('id', id.toString());
    formData.append('titulo', titulo);
    formData.append('largura', largura.toString());
    formData.append('inicio', inicio);
    formData.append('fim', fim);
    if (imagem) formData.append('imagem', imagem);
    if (texto) formData.append('texto', texto);
    if (botao) formData.append('botao', botao);
    if (link) formData.append('link', link);
    await this.http.post('/sitecreci/comunicado', formData);
  }

  /**
   * Deleta um comunicado.
   * @param {number} id Chave primaria.
   * @returns {Promise<void>}
   */
  async deleteComunicado(id) {
    await this.http.delete('/sitecreci/comunicado', {params: {id: id.toString()}});
  }

  //=== Calendario ===//

  /**
   * Obtem todos os eventos do calendario.
   * @returns {Promise<any[]>}
   */
  async getCalendarioEventos() {
    const {data} = await this.http.get('/calendario');
    return data;
  }

  /**
   * Obtem um evento do calendario.
   * @param {number} id Chave primaria
   * @returns {Promise<any>}
   */
  async getCalendarioEvento(id) {
    const {data} = await this.http.get('/calendario', {params: {id: id.toString()}});
    return data;
  }

  /**
   * Insere um evento no calendário.
   * @param {string} nome Titulo do evento.
   * @param {number} cor Id da cor.
   * @param {string} texto Texto descritivo.
   * @param {string} datetime_inicio
   * @param {string|null} datetime_fim Opcional, data do fim do evento.
   * @param {boolean} publico Se a informação pode ser exibida a público.
   * @param {number|null} sala
   * @returns {Promise<number>} Retorna o Id.
   */
  async insertCalendarioEvento(nome, cor, texto = '', datetime_inicio, datetime_fim = null, publico = false, sala = null) {
    const formData = new FormData();
    formData.append('nome', nome);
    formData.append('inicio', datetime_inicio);
    if (datetime_fim) formData.append('fim', datetime_fim);
    formData.append('cor', cor.toString());
    formData.append('publico', publico ? '1' : '0');
    formData.append('texto', texto.trim());
    if (sala) formData.append('sala', sala.toString());
    const {data} = await this.http.post('/calendario', formData);
    return data;
  }

  /**
   * Atualiza um evento do calendário.
   * @param {number} id Chave primaria.
   * @param {string} nome Titulo do evento.
   * @param {number} cor Id da cor.
   * @param {string} texto Texto descritivo.
   * @param {string} datetime_inicio
   * @param {string|null} datetime_fim Opcional, data do fim do evento.
   * @param {boolean} publico Se a informação pode ser exibida á público.
   * @param {number|null} sala
   * @returns {Promise<void>}
   */
  async updateCalendarioEvento(id, nome, cor, texto = '', datetime_inicio, datetime_fim = null, publico = false, sala = null) {
    const formData = new FormData();
    formData.append('id', id.toString());
    formData.append('nome', nome);
    formData.append('inicio', datetime_inicio);
    if (datetime_fim) formData.append('fim', datetime_fim);
    formData.append('cor', cor.toString());
    formData.append('publico', publico ? '1' : '0');
    formData.append('texto', texto.trim());
    if (sala) formData.append('sala', sala.toString());
    await this.http.post('/calendario', formData);
  }

  /**
   * Deleta um evento do calendário.
   * @param {number} id Chave primaria.
   * @returns {Promise<void>}
   */
  async deleteCalendarioEvento(id) {
    await this.http.delete('/calendario', {params: {id: id.toString()}});
  }

  /**
   * Obtem as cores cadastradas para uso no calendario.
   * @returns {Promise<any[]>}
   */
  async getCalendarioCores() {
    const {data} = await this.http.get('/calendario/cor');
    return data;
  }

  /**
   * Obtem uma cor cadastrada para uso no calendario.
   * @param id
   * @returns {Promise<any>}
   */
  async getCalendarioCor(id) {
    const {data} = await this.http.get('/calendario/cor', {params: {id: id.toString()}});
    return data;
  }

  /**
   * Insere uma nova cor no cadastro de cores para uso no calendario.
   * @param {string} nome Titulo para uso da cor, exemplo: Reunião.
   * @param {string} valor Código HEX.
   * @returns {Promise<number>} Retorna o Id.
   */
  async insertCalendarioCor(nome, valor) {
    const formData = new FormData();
    formData.append('nome', nome);
    formData.append('valor', valor);
    const {data} = await this.http.post('/calendario/cor', formData);
    return data;
  }

  /**
   * Atualiza um cor do cadastro de cores do calendario.
   * @param {number} id Chave primaria.
   * @param {string} nome Titulo para uso da cor, exemplo: Reunião.
   * @param {string} valor Código HEX.
   * @returns {Promise<void>}
   */
  async updateCalendarioCor(id, nome, valor) {
    const formData = new FormData();
    formData.append('id', id.toString());
    formData.append('nome', nome);
    formData.append('valor', valor);
    await this.http.post('/calendario/cor', formData);
  }

  /**
   * Deleta uma cor do calendário. Mas é necessário que ela não esteja em uso.
   * @param {number} id
   * @returns {Promise<void>}
   */
  async deleteCalendarioCor(id) {
    await this.http.delete('/calendario/cor', {params: {id: id.toString()}});
  }

  //=== Evento ===//

  /**
   * Lista os eventos.
   * @returns {Promise<any[]>}
   */
  async getEventos() {
    const {data} = await this.http.get('/sitecreci/evento');
    return data;
  }

  /**
   * Obtem um evento
   * @param {number} id Chave primaria
   * @returns {Promise<any>}
   */
  async getEvento(id) {
    const {data} = await this.http.get('/sitecreci/evento', {params: {id: id.toString()}});
    return data;
  }

  /**
   * Insere um evento.
   * @param {string} titulo
   * @param {Blob} imgBanner
   * @param {Blob} imgCapa
   * @param {string} datetimeInicio
   * @param {string} datetimeFim
   * @param {string} descricao
   * @param {string} local
   * @param {string} endereco
   * @param {string} taxa
   * @param {number} vagas
   * @param {boolean} somenteCorretor
   * @param {boolean} somenteAptos
   * @returns {Promise<number>} ID do evento.
   */
  async insertEvento(titulo, imgBanner, imgCapa, datetimeInicio, datetimeFim = null, descricao = null, local = null, endereco = null, taxa = null, vagas = null, somenteCorretor = false, somenteAptos = false) {
    const formData = new FormData();
    formData.append('titulo', titulo);
    formData.append('imagem', imgBanner);
    formData.append('capa', imgCapa);
    formData.append('inicio', datetimeInicio);
    if (datetimeFim) formData.append('fim', datetimeFim);
    if (descricao) formData.append('descricao', descricao);
    if (descricao) formData.append('local', local);
    if (descricao) formData.append('endereco', endereco);
    if (descricao) formData.append('taxa', taxa);
    if (descricao) formData.append('vagas', vagas.toString());
    if (descricao) formData.append('corretor', somenteCorretor ? '1' : '0');
    if (descricao) formData.append('aptos', somenteAptos ? '1' : '0');
    const {data} = await this.http.post('/sitecreci/evento', formData);
    return data;
  }

  /**
   * Atualiza um evento.
   * @param {number} id
   * @param {string} titulo
   * @param {Blob} imgBanner
   * @param {Blob} imgCapa
   * @param {string} datetimeInicio
   * @param {string} datetimeFim
   * @param {string} descricao
   * @param {string} local
   * @param {string} endereco
   * @param {string} taxa
   * @param {number} vagas
   * @param {boolean} somenteCorretor
   * @param {boolean} somenteAptos
   * @returns {Promise<number>}
   */
  async updateEvento(id, titulo, imgBanner, imgCapa, datetimeInicio, datetimeFim = null, descricao = null, local = null, endereco = null, taxa = null, vagas = null, somenteCorretor = false, somenteAptos = false) {
    const formData = new FormData();
    formData.append('id', id.toString());
    formData.append('titulo', titulo);
    formData.append('imagem', imgBanner);
    formData.append('capa', imgCapa);
    formData.append('inicio', datetimeInicio);
    if (datetimeFim) formData.append('fim', datetimeFim);
    if (descricao) formData.append('descricao', descricao);
    if (descricao) formData.append('local', local);
    if (descricao) formData.append('endereco', endereco);
    if (descricao) formData.append('taxa', taxa);
    if (descricao) formData.append('vagas', vagas.toString());
    if (descricao) formData.append('corretor', somenteCorretor ? '1' : '0');
    if (descricao) formData.append('aptos', somenteAptos ? '1' : '0');
    await this.http.post('/sitecreci/evento', formData);
  }

  /**
   * Remove um evento
   * @param {number} id Chave primaria.
   * @returns {Promise<void>}
   */
  async deleteEvento(id) {
    await this.http.delete('/sitecreci/evento', {params: {id: id.toString()}});
  }

  /**
   * Obtem a lista de inscritos do evento.
   * @param {number} eventoId Chave primaria do evento.
   * @returns {Promise<any[]>}
   */
  async getEventoInscricoes(eventoId) {
    const {data} = await this.http.get('/sitecreci/evento/inscricao', {params: {id: eventoId.toString()}});
    return data;
  }

  /**
   * Insere uma inscrição.
   * @param {number} eventoId Chave primaria do evento
   * @param {string} cpf
   * @param {string} nome
   * @param {string} fone
   * @param {string} email
   * @param {number} creci Numero de creci caso seja corretor de imoveis
   * @returns {Promise<number>}
   */
  async insertEventoInscricao(eventoId, cpf, nome, fone, email, creci = null) {
    const formData = new FormData();
    formData.append('evento', eventoId.toString());
    formData.append('cpf', cpf);
    formData.append('nome', nome);
    formData.append('fone', fone);
    formData.append('email', email);
    if (creci) formData.append('creci', creci.toString());
    const {data} = await this.http.post('/sitecreci/evento/inscricao', formData);
    return data;
  }

  /**
   * Marca um inscrito de evento como presente ou falta.
   * @param {number} inscricaoId
   * @param {boolean} presente
   * @returns {Promise<void>}
   */
  async updateEventoInscricaoPresenca(inscricaoId, presente = true) {
    const formData = new FormData();
    formData.append('id', inscricaoId.toString());
    formData.append('status', presente ? '1' : '0');
    await this.http.put('/sitecreci/evento/inscricao', formData);
  }

  //=== Link de download ===//

  async getLinksDownload() {
    const {data} = await this.http.get('/sitecreci/link');
    return data;
  }

  async getLinkDownloadFile(id) {
    const response = await this.http.get('/sitecreci/link', {
      params: {id: id.toString()},
      responseType: 'blob',
    });
    if (response.status === 200) return response.data;
    else if (response.status === 404) return null;
    else return false;
  }

  getLinkDownloadUrl(id) {
    let baseApi = this.http.defaults.baseURL;
    if (baseApi.substr(0, 4) !== 'http') {
      let baseBrowser = window.location.href.split('#')[0];
      if (baseBrowser.substr(baseBrowser.length -1) === '/') baseBrowser = baseBrowser.substr(0, baseBrowser.length -1);
      baseApi = baseBrowser + baseApi;
    }
    const final = 'sitecreci/link?id=' + id;
    return baseApi + final;
  }

  async insertLinkDownload(arquivo) {
    const formData = new FormData();
    formData.append('arquivo', arquivo);
    const {data} = await this.http.post('/sitecreci/link', formData);
    return data;
  }

  async deleteLinksDownload(id) {
    await this.http.delete('/sitecreci/link', {params: {id: id.toString()}});
  }

  //=== Convenio ===//
  /**
   * Obtem a lista de convenios e de categorias
   * @returns {Promise<any[]>}
   */
  async getConvenios() {
    const {data} = await this.http.get('/sitecreci/convenio');
    return data;
  }

  /**
   * Obtem todos os dados do convenio.
   * @param {number} id
   * @returns {Promise<void>}
   */
  async getConvenio(id) {
    const {data} = await this.http.get('/sitecreci/convenio?id=' + id);
    return data;
  }

  /**
   * Registra um novo convenio.
   * @param {number} categoria
   * @param {string} empresa
   * @param {string} texto
   * @param {Blob} imagem
   * @param {Blob} miniatura
   * @param {string} vencimento
   * @returns {Promise<void>}
   */
  async insertConvenio(categoria, empresa, texto, imagem, miniatura, vencimento = null) {
    const formData = new FormData();
    formData.append('categoria', categoria.toString());
    formData.append('empresa', empresa);
    formData.append('texto', texto);
    formData.append('imagem', imagem);
    formData.append('miniatura', miniatura);
    if (vencimento) formData.append('vencimento', vencimento);
    await this.http.post('/sitecreci/convenio', formData);
  }

  /**
   * Atualiza um convenio.
   * @param {number} id
   * @param {number} categoria
   * @param {string} empresa
   * @param {string} texto
   * @param {Blob} imagem
   * @param {Blob} miniatura
   * @param {string} vencimento
   * @returns {Promise<void>}
   */
  async updateConvenio(id, categoria, empresa, texto, imagem, miniatura, vencimento = null) {
    const formData = new FormData();
    formData.append('id', id.toString());
    formData.append('categoria', categoria.toString());
    formData.append('empresa', empresa);
    formData.append('texto', texto);
    formData.append('imagem', imagem);
    formData.append('miniatura', miniatura);
    if (vencimento) formData.append('vencimento', vencimento);
    await this.http.put('/sitecreci/convenio', formData);
  }

  /**
   * Apaga um convenio.
   * @param {number} id
   * @returns {Promise<void>}
   */
  async deleteConvenio(id) {
    const {data} = await this.http.delete('/sitecreci/convenio?id=' + id);
    return data;
  }
}
