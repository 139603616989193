<template>
  <v-app :dark="$store.state.dark">
    <v-navigation-drawer
      app
      clipped
      v-if="$store.getters['session/logged']"
      v-model="showMenu"
    >
      <template v-slot:prepend>
        <v-list dense class="py-0">
          <v-list-item two-line :to="'/usuario/' + $store.getters['session/payload'].user">
            <v-list-item-avatar>
              <v-avatar color="primary" class="font-weight-medium white--text elevation-2">
                <!-- Use <img> here -->
                <template>{{nameInitials}}</template>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{shortName}}</v-list-item-title>
              <v-list-item-subtitle>{{occupation}}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
      <v-divider/>
      <v-list dense nav>
        <v-list-item-group color="primary">
          <v-list-item to="/">
            <v-list-item-icon>
              <v-icon>mdi-home</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Início</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/calendario">
            <v-list-item-icon>
              <v-icon>mdi-calendar</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Agenda</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/carp">
            <v-list-item-icon>
              <v-icon>mdi-card-account-details</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>CARP</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/diretoria">
            <v-list-item-icon>
              <v-icon>mdi-sitemap</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Diretoria</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/manuais">
            <v-list-item-icon>
              <v-icon>mdi-file-document-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Manuais de instruções</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/relatorios" v-if="$store.getters['session/checkPermission'](5)">
            <v-list-item-icon>
              <v-icon>mdi-file-chart</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Relatórios</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/sitecreci" v-if="$store.getters['session/checkPermission'](1)">
            <v-list-item-icon>
              <v-icon>mdi-web</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Site do CRECI</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/suporte">
            <v-list-item-icon>
              <v-icon>mdi-face-agent</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Suporte</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/tecin" v-if="$store.getters['session/checkPermission'](6)">
            <v-list-item-icon>
              <v-icon>mdi-server-network</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>TECIN</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <div class="px-2 pb-3" v-if="!$vuetify.breakpoint.lgAndUp">
          <v-btn
            @click="exit"
            color="primary"
            class="white--text"
            block
          >
            <v-icon class="mr-1">mdi-power-standby</v-icon>SAIR
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>
    <v-app-bar
      app
      dense
      dark
      clipped-left
      v-if="$store.getters['session/logged']"
      :color="$store.state.dark ? 'secondary' : 'primary'"
    >
      <v-app-bar-nav-icon @click.stop="showMenu = !showMenu" />
      <v-toolbar-title class="ml-0 pl-4">{{$vuetify.breakpoint.lgAndUp ? config.appBarTitle : config.appBarTitleMobile}}</v-toolbar-title>
      <v-spacer/>
      <v-menu
        left
        bottom
      >
        <template v-slot:activator="{ on: menu, attrs }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on: tooltip }">
              <v-btn icon v-on="{ ...menu, ...tooltip }" v-bind="attrs">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <span>Opções</span>
          </v-tooltip>
        </template>
        <v-list dense class="py-0">
          <v-list-item :to="'/usuario/' + $store.getters['session/payload'].user">
            <v-list-item-content>
              <v-list-item-title class="option-item">
                <v-icon class="mr-2">mdi-account</v-icon>Minha conta
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/usuarios">
            <v-list-item-content>
              <v-list-item-title class="option-item">
                <v-icon class="mr-2">mdi-account-multiple</v-icon>Colaboradores
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider></v-divider>
          <v-list-item @click="exit">
            <v-list-item-content>
              <v-list-item-title class="option-item red--text">
                <v-icon class="mr-2" color="red">mdi-power-standby</v-icon>Sair
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-main style="background-color: #f5f6f8">
      <transition name="fade-transition" @after-leave="scrollTop" mode="out-in">
        <router-view/>
      </transition>
    </v-main>
    <v-footer
      app
      dark
      class="d-print-none justify-space-between py-1"
      :color="$store.state.dark ? 'secondary' : 'primary'"
    >
      <span>{{$vuetify.breakpoint.mdAndUp ? 'Conselho Regional dos Corretores de Imóveis 14ª Região' : 'CRECI-MS'}}</span>
      <span>{{version}}</span>
    </v-footer>
    <g-snackbar/>
    <g-alert/>
    <g-loader/>
  </v-app>
</template>

<script>
import { config } from '@/config';
import GSnackbar from '@/components/global/GSnackbar';
import GAlert from '@/components/global/GAlert';
import GLoader from '@/components/global/GLoader';
import StringHelper from '@/helpers/StringHelper';
import { version } from '../package.json';
export default {
  name: 'App',
  components: {GLoader, GAlert, GSnackbar},
  data: () => ({
    showMenu: null,
    config,
    version,
  }),
  methods: {
    scrollTop() {
      window.scrollTo(0, 0);
    },
    exit() {
      this.showMenu = false;
      this.$store.dispatch('session/destroy');
    },
  },
  computed: {
    shortName() {
      const name = this.$store.getters["session/payload"] ? this.$store.getters["session/payload"].name : '';
      return name ? StringHelper.shortName(name.toUpperCase()) : '';
    },
    nameInitials() {
      return this.shortName ? StringHelper.nameInitials(this.shortName) : '';
    },
    occupation() {
      return this.$store.getters["session/payload"] ? this.$store.getters["session/payload"].occupation.toUpperCase() : '';
    },
  }
}
</script>

<style>
  .option-item {
    display: flex;
    align-items: center;
  }
</style>
