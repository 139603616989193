export default [
  {
    path: '/relatorios',
    name: 'Relatorios',
    component: () => import(/* webpackChunkName: "relatorios" */ '../views/Relatorios/Index'),
  },
  {
    path: '/relatorios/conselhonet',
    name: 'Relatorios-Conselhonet',
    component: () => import(/* webpackChunkName: "relatorios" */ '../views/Relatorios/conselhonet/Index'),
  },
  {
    path: '/relatorios/conselhonet/fichascadastrais',
    name: 'Relatorios-Conselhonet-Fichascadastrais',
    component: () => import(/* webpackChunkName: "relatorios" */ '../views/Relatorios/conselhonet/FichasCadastrais'),
  },
  {
    path: '/relatorios/conselhonet/fotos',
    name: 'Relatorios-Conselhonet-Fotos',
    component: () => import(/* webpackChunkName: "relatorios" */ '../views/Relatorios/conselhonet/Fotos'),
  },
  {
    path: '/relatorios/conselhonet/cidadescomprofissionais',
    name: 'Relatorios-Conselhonet-Cidadescomprofissionais',
    component: () => import(/* webpackChunkName: "relatorios" */ '../views/Relatorios/conselhonet/CidadesComProfissionais'),
  },
  {
    path: '/relatorios/conselhonet/semfotoass',
    name: 'Relatorios-Conselhonet-SemFotoAss',
    component: () => import(/* webpackChunkName: "relatorios" */ '../views/Relatorios/conselhonet/SemFotoAss'),
  },
  {
    path: '/relatorios/icorretor',
    name: 'Relatorios-icorretor',
    component: () => import(/* webpackChunkName: "relatorios" */ '../views/Relatorios/icorretor/Index'),
  },
  {
    path: '/relatorios/emailcorretor',
    name: 'Relatorios-emailcorretor',
    component: () => import(/* webpackChunkName: "relatorios" */ '../views/Relatorios/emailcorretor/Index'),
  },
];