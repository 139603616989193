/**
 * Configurações da aplicação
 * @type {{loginSubtitle: string, appBarTitleMobile: string, pageTitle: string, http: {baseUrl: string, baseUrl_api2: string, timeout: number}, loginTitle: string, appBarTitle: string}}
 */
export const config = {
  appBarTitle: 'SIS - Sistema Integrado de Soluções',
  appBarTitleMobile: 'SIS',
  footerTitle: 'CRECI/MS - Setor de Tecnologia da Informação',
  footerTitleMobile: 'CRECI/MS',
  http: {
    baseUrl: 'https://sis.crecims.gov.br/api/',
    baseUrl_api2: 'https://sis.crecims.gov.br/api2/public/',
    timeout: 90000,
  },
  loginTitle: 'CRECI-MS',
  loginSubtitle: 'SIS - Sistema Integrado de Soluções',
  pageTitle: 'SIS - Sistema Integrado de Soluções',
};
