export default [
  {
    path: '/diretoria',
    name: 'Diretoria',
    component: () => import(/* webpackChunkName: "diretoria" */ '../views/Diretoria/Index'),
  },
  {
    path: '/diretoria/comissoes',
    name: 'Diretoria-Comissoes',
    component: () => import(/* webpackChunkName: "diretoria" */ '../views/Diretoria/Comissoes'),
  },
  {
    path: '/diretoria/conselheiros',
    name: 'Diretoria-Conselheiros',
    component: () => import(/* webpackChunkName: "diretoria" */ '../views/Diretoria/Conselheiros'),
  },
  {
    path: '/diretoria/delegados',
    name: 'Diretoria-Delegados',
    component: () => import(/* webpackChunkName: "diretoria" */ '../views/Diretoria/Delegados'),
  },
  {
    path: '/diretoria/diretores',
    name: 'Diretoria-Diretores',
    component: () => import(/* webpackChunkName: "diretoria" */ '../views/Diretoria/Diretores'),
  },
];