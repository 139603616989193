<template>
  <div>
    <div class="d-flex align-center justify-center mt-3">
      <img alt="App logo" src="../assets/logo.svg" class="elevation-3 rounded-circle" style="height: 4rem">
      <div class="ml-3">
        <p class="subtitle-2 mb-0">Sistema Integrado de Soluções</p>
        <p class="caption mb-0">Versão 2.0 em Beta Test</p>
      </div>
    </div>
    <v-row>
      <v-col cols="12" xl="6">
        <Calendario></Calendario>
      </v-col>
      <v-col cols="12" xl="6">
        <Chamados somente-em-andamento></Chamados>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Calendario from '@/views/Calendario/Index';
import Chamados from "@/views/Suporte/Chamados";
export default {
  name: 'Home',
  components: {Chamados, Calendario},
}
</script>
