export default [
    {
        path: '/tecin',
        name: 'TecinMenu',
        component: () => import(/* webpackChunkName: "suporte" */ '../views/Tecin/TecinMenu'),
    },
    {
        path: '/tecin/computadores',
        name: 'TecinComputadores',
        component: () => import(/* webpackChunkName: "suporte" */ '../views/Tecin/Computadores'),
    },
    {
        path: '/tecin/impressoras',
        name: 'TecinImpressoras',
        component: () => import(/* webpackChunkName: "suporte" */ '../views/Tecin/Impressoras'),
    },
    {
        path: '/tecin/esquemas',
        name: 'TecinEsquemas',
        component: () => import(/* webpackChunkName: "suporte" */ '../views/Tecin/Esquemas'),
    },
    {
        path: '/suporte/consumiveis',
        name: 'TecinConsumiveis',
        component: () => import(/* webpackChunkName: "suporte" */ '../views/Tecin/Consumiveis'),
    },
    {
        path: '/suporte/patrimonios',
        name: 'TecinPatrimonios',
        component: () => import(/* webpackChunkName: "suporte" */ '../views/Tecin/Patrimonios'),
    },
];