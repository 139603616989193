export default [
  {
    path: '/suporte',
    name: 'Suporte',
    component: () => import(/* webpackChunkName: "suporte" */ '../views/Suporte/Index'),
  },
  {
    path: '/suporte/chamados',
    name: 'Suporte-Chamados',
    component: () => import(/* webpackChunkName: "suporte" */ '../views/Suporte/Chamados'),
  },
  {
    path: '/suporte/fila',
    name: 'Suporte-Fila',
    component: () => import(/* webpackChunkName: "suporte" */ '../views/Suporte/Fila'),
  },
  {
    path: '/suporte/chamadoeditor',
    name: 'Suporte-Chamadoeditor',
    component: () => import(/* webpackChunkName: "suporte" */ '../views/Suporte/ChamadoEditor'),
  },
  {
    path: '/suporte/chamado/:id',
    name: 'Suporte-Chamado',
    component: () => import(/* webpackChunkName: "suporte" */ '../views/Suporte/Chamado'),
    props: true,
  },
];