import { httpSnackbar, httpSilent } from '@/plugins/axios'

export default class UserWebClient {
  http = httpSnackbar

  /**
   * Construtor
   * @param {boolean} silent
   */
  constructor(silent = false) {
    if (silent) this.http = httpSilent;
  }

  /**
   * Obtem a listagem de usuarios.
   * @param {boolean} withAvatar O resultado trará também o avatar em base64.
   * @returns {Promise<any[]>}
   */
  async getUsuarios(withAvatar = false) {
    const {data} = await this.http.get('/usuario', {params: {'avatar': withAvatar ? 'S' : undefined}});
    return data;
  }

  /**
   * Obtem os dados de um usuario.
   * @param {string} username_cpf Nome de usuario ou CPF.
   * @returns {Promise<any>}
   */
  async getUsuario(username_cpf) {
    const {data} = await this.http.get('/usuario', {params: {id: username_cpf}});
    return data;
  }

  /**
   * Insere uma nova conta de usuário.
   * @param {string} username Nome de usuário.
   * @param {string} cpf Numeros do CPF.
   * @param {string} nome Nome completo.
   * @param {string} apelido Nome para exibição no sistema.
   * @param {string} email Email operacional.
   * @param {string} cargo Função ou cargo.
   * @param {1|2} sexo Sexualidade. 1=Feminino,2=Masculino.
   * @returns {Promise<void>}
   */
  async insertUsuario(username, cpf, nome, apelido, email, cargo, sexo) {
    const formData = new FormData();
    formData.append('usuario', username);
    formData.append('cpf', cpf);
    formData.append('nome', nome);
    formData.append('apelido', apelido);
    formData.append('email', email);
    formData.append('cargo', cargo);
    formData.append('sexo', sexo.toString());
    await this.http.post('/usuario', formData);
  }

  /**
   * Atualiza os dados de uma conta de usuário.
   * @param {string} username Chave primaria para identificação.
   * @param {string} cpf Numeros do CPF.
   * @param {string} nome Nome completo.
   * @param {string} apelido Nome para exibição no sistema.
   * @param {string} email Email operacional.
   * @param {string} cargo Função ou cargo.
   * @param {1|2} sexo Sexualidade. 1=Feminino,2=Masculino.
   * @returns {Promise<void>}
   */
  async updateUsuario(username, cpf, nome, apelido, email, cargo, sexo) {
    const formData = new FormData();
    formData.append('usuario', username);
    formData.append('cpf', cpf);
    formData.append('nome', nome);
    formData.append('apelido', apelido);
    formData.append('email', email);
    formData.append('cargo', cargo);
    formData.append('sexo', sexo.toString());
    await this.http.put('/usuario', formData);
  }

  /**
   * Contas recém criadas não possuem senha. Este procedimento cria a primeira senha do usuário.
   * @param {string} cpf Para identificar o usuario no banco.
   * @param {string} email Para validação de segurança, o email deve coincidir com o do cadastro.
   * @param {string} novaSenha Nova senha do usuário.
   * @returns {Promise<string>}
   */
  async ativarSenha(cpf, email, novaSenha) {
    const formData = new FormData();
    formData.append('cpf', cpf);
    formData.append('email', email);
    formData.append('senha', novaSenha);
    const {data} = await this.http.put('/usuario/passwd', formData);
    return data;
  }

  /**
   * Solicita um reset de senha para um usuario. Ele receberá uma nova senha por email.
   * @param {string} username Chave primaria para identificar o usuario.
   * @param {string} email Email do usuario, deve coincidir com o banco de dados.
   * @returns {Promise<void>}
   */
  async resetSenha(username, email) {
    const params = {
      usuario: username,
      email: email,
    };
    await this.http.delete('/usuario/passwd', {params});
  }

  /**
   * Altera a senha do usuário atualmente logado.
   * @param {string} novaSenha Senha nova.
   * @param {string} velhaSenha Senha atual.
   * @returns {Promise<void>}
   */
  async updateSenha(novaSenha, velhaSenha) {
    const formData = new FormData();
    formData.append('novasenha', novaSenha);
    formData.append('velhasenha', velhaSenha);
    await this.http.post('/usuario/passwd', formData);
  }

  /**
   * Obtem as permissões existentes do sistema. Ou verifique quais permissões um usuário possui.
   * @param {string} usuario Username.
   * @returns {Promise<any[]>}
   */
  async getPermissoes(usuario = null) {
    const {data} = await this.http.get('/usuario/permissao', {params: {usuario: usuario ? usuario : undefined}});
    return data;
  }

  /**
   * Concede ou remove uma permissão de um usuário.
   * @param {string} usuario
   * @param {number} permissaoId
   * @param {boolean} valor
   * @returns {Promise<void>}
   */
  async updatePermissao(usuario, permissaoId, valor) {
    const formData = new FormData();
    formData.append('usuario', usuario);
    formData.append('permissao', permissaoId.toString());
    formData.append('valor', valor ? '1' : '0');
    await this.http.post('/usuario/permissao', formData);
  }

  /**
   * Obtem o Blob da imagem de capa de um usuario.
   * @param {string} username_cpf Nome de usuario ou CPF.
   * @param {boolean} useGeneric Quando o usuario não possuir foto, uma genérica será retornada.
   * @returns {Promise<Blob|boolean>} Blob da imagem. False caso não queira receber imagem genérica.
   */
  async getUsuarioCapa(username_cpf, useGeneric = true) {
    const response = await this.http.get('/usuario/capa', {
      params: {usuario: username_cpf, nogeneric: useGeneric ? undefined : 'S'},
      responseType: 'blob',
      validateStatus: status => status === 200 || status === 404,
    });
    if (response.status === 404 && !useGeneric) return false;
    return response.data;
  }

  /**
   * Obtem a URL da imagem de capa do perfil de um usuario.
   * @param {string} usuario Nome de usuario, chave primaria.
   * @returns {string}
   */
  getUsuarioCapaUrl(usuario) {
    return this.http.defaults.baseURL + 'usuario/capa?usuario=' + usuario;
  }

  /**
   * Obtem o Blob da imagem de avatar de um usuario.
   * @param {string} username_cpf Nome de usuario ou CPF.
   * @param {boolean} useGeneric Quando o usuario não possuir foto, uma genérica será retornada.
   * @returns {Promise<Blob|boolean>} Blob da imagem. False caso não queira receber imagem genérica.
   */
  async getUsuarioAvatar(username_cpf, useGeneric = true) {
    const response = await this.http.get('/usuario/avatar', {
      params: {usuario: username_cpf, nogeneric: useGeneric ? undefined : 'S'},
      responseType: 'blob',
      validateStatus: status => status === 200 || status === 404,
    });
    if (response.status === 404 && !useGeneric) return false;
    return response.data;
  }

  /**
   * Obtem a URL do avatar de um usuario.
   * @param {string} usuario Nome de usuario, chave primaria.
   * @returns {string}
   */
  getUsuarioAvatarUrl(usuario) {
    return this.http.defaults.baseURL + 'usuario/avatar?usuario=' + usuario;
  }

  /**
   * Atualiza o avatar do usuário atualmente logado.
   * @param {Blob} avatar
   * @returns {Promise<void>}
   */
  async setUsuarioAvatar(avatar) {
    const formData = new FormData();
    formData.append('avatar', avatar);
    await this.http.post('/usuario/avatar', formData);
  }

  /**
   * Atualiza a imagem de capa do perfil do usuário atualmente logado.
   * @param {Blob} avatar
   * @returns {Promise<void>}
   */
  async setUsuarioCapa(avatar) {
    const formData = new FormData();
    formData.append('capa', avatar);
    await this.http.post('/usuario/capa', formData);
  }

  /**
   * Obtem a imagem genérica de Avatar de usuário.
   * @returns {Promise<Blob>}
   */
  async getGenericAvatar() {
    const response = await this.http.get('/usuario/avatar', {
      params: {'generico': 'S'},
      responseType: 'blob',
    });
    return response.data;
  }

  /**
   * Obtem a imagem genérica de Capa de usuário.
   * @returns {Promise<Blob>}
   */
  async getGenericCapa() {
    const response = await this.http.get('/usuario/capa', {
      params: {'generico': 'S'},
      responseType: 'blob',
    });
    return response.data;
  }

  async desativarUsuario(username) {
    await this.http.delete('/usuario?usuario=' + username);
  }
  async reativarUsuario(username) {
    const formData = new FormData();
    formData.append('usuario', username);
    await this.http.patch('/usuario', formData);
  }
}
