import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Error from '@/views/Error.vue'
import Login from '@/views/Login.vue'
import store from '@/store'
import diretoria from './diretoria';
import relatorios from './relatorios';
import sitecreci from './sitecreci';
import suporte from './suporte';
import tecin from './tecin';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '*',
    name: 'Error',
    component: Error
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/usuario/:usuario',
    name: 'Usuario',
    component: () => import('../views/Config/Usuario'),
    props: true,
  },
  {
    path: '/usuarios',
    name: 'Usuarios',
    component: () => import('../views/Config/Usuarios'),
  },
  {
    path: '/carp',
    name: 'Carp',
    component: () => import('../views/Carp'),
  },
  {
    path: '/calendario',
    name: 'Calendario',
    component: () => import('../views/Calendario/Index'),
  },
  {
    path: '/calendario/evento/:id?',
    name: 'Calendario-editor',
    component: () => import('../views/Calendario/EventEditor'),
    props: true,
  },
  {
    path: '/manuais',
    name: 'Manuais',
    component: () => import('../views/Manual/Manuais'),
  },
  {
    path: '/manual/:id?',
    name: 'Manual',
    component: () => import('../views/Manual/Manual'),
    props: true,
  },
  ...diretoria,
  ...relatorios,
  ...sitecreci,
  ...suporte,
  ...tecin,
];

const router = new VueRouter({
  routes
});

router.beforeEach((to, from, next) => {
  if (store.getters['session/logged']) {
    if (to.name === 'Login') next('/');
    else next();
  } else {
    if (to.name === 'Login') next();
    else next({ name: 'Login' });
  }
});

export default router;
