import Vue from 'vue'
import * as loadImage from 'blueimp-load-image'

const loadImageAsync = function (img_blob_file_url, options) {
  return new Promise((resolve, reject) => {
    const loader = loadImage(img_blob_file_url, result => resolve(result), options);
    if (!loader) reject();
    loader.onerror = () => reject();
  });
};

Vue.prototype.$loadImageAsync = loadImageAsync;
Vue.prototype.$loadImage = loadImage;

export { loadImageAsync, loadImage };
