export default [
  {
    path: '/sitecreci',
    name: 'Site-creci',
    component: () => import(/* webpackChunkName: "sitecreci" */ '../views/Sitecreci/Index'),
  },
  {
    path: '/sitecreci/comunicados',
    name: 'Site-creci-comunicados',
    component: () => import(/* webpackChunkName: "sitecreci" */ '../views/Sitecreci/Comunicados'),
  },
  {
    path: '/sitecreci/comunicado/:id?',
    name: 'Site-creci-comunicado',
    component: () => import(/* webpackChunkName: "sitecreci" */ '../views/Sitecreci/ComunicadoEditor'),
    props: true,
  },
  {
    path: '/sitecreci/destaques',
    name: 'Site-creci-destaques',
    component: () => import(/* webpackChunkName: "sitecreci" */ '../views/Sitecreci/Destaques'),
  },
  {
    path: '/sitecreci/destaque/:id?',
    name: 'Site-creci-destaque',
    component: () => import(/* webpackChunkName: "sitecreci" */ '../views/Sitecreci/DestaqueEditor'),
    props: true,
  },
  {
    path: '/sitecreci/eventos',
    name: 'Site-creci-eventos',
    component: () => import(/* webpackChunkName: "sitecreci" */ '../views/Sitecreci/Eventos'),
  },
  {
    path: '/sitecreci/evento/:id?',
    name: 'Site-creci-evento',
    component: () => import(/* webpackChunkName: "sitecreci" */ '../views/Sitecreci/EventoEditor'),
    props: true,
  },
  {
    path: '/sitecreci/evento/inscricoes/:evento',
    name: 'Site-creci-evento-inscricoes',
    component: () => import(/* webpackChunkName: "sitecreci" */ '../views/Sitecreci/EventoInscricoes'),
    props: true,
  },
  {
    path: '/sitecreci/noticias',
    name: 'Site-creci-noticias',
    component: () => import(/* webpackChunkName: "sitecreci" */ '../views/Sitecreci/Noticias'),
  },
  {
    path: '/sitecreci/noticia/:id?',
    name: 'Site-creci-noticia',
    component: () => import(/* webpackChunkName: "sitecreci" */ '../views/Sitecreci/NoticiaEditor'),
    props: true,
  },
  {
    path: '/sitecreci/palavrapresidente',
    name: 'Site-creci-palavrapresidente',
    component: () => import(/* webpackChunkName: "sitecreci" */ '../views/Sitecreci/PalavraPresidente'),
  },
  {
    path: '/sitecreci/textoinstitucional',
    name: 'Site-creci-textoinstitucional',
    component: () => import(/* webpackChunkName: "sitecreci" */ '../views/Sitecreci/TextoInstitucional'),
  },
  {
    path: '/sitecreci/geradorlink',
    name: 'Site-creci-geradorlink',
    component: () => import(/* webpackChunkName: "sitecreci" */ '../views/Sitecreci/GeradorLink'),
  },
  {
    path: '/sitecreci/convenios',
    name: 'convenios',
    component: () => import(/* webpackChunkName: "sitecreci" */ '../views/Sitecreci/Convenios'),
  },
  {
    path: '/sitecreci/convenio/:id?',
    name: 'convenio',
    component: () => import(/* webpackChunkName: "sitecreci" */ '../views/Sitecreci/Convenio'),
    props: true,
  },
];