<template>
  <async-container fluid :loading="!loaded">
    <v-card elevation="3" max-width="100rem" class="mx-auto">
      <v-card-title class="title-bar justify-space-between">
        Calendário institucional
        <v-btn v-if="!hideAddButton" color="success" to="/calendario/evento" small>
          <v-icon class="mr-1">mdi-plus-circle</v-icon>
          Add Compromisso
        </v-btn>
      </v-card-title>
      <div class="d-flex justify-space-between">
        <v-sheet
            tile
            class="d-flex align-center justify-center"
        >
          <!-- Botão Anterior -->
          <v-btn
              icon
              class="ma-1"
              @click="$refs.calendar.prev()"
          >
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <p class="mb-0 text-capitalize text-center" style="line-height: 1">
            {{currentDate}}
            <span v-if="type === 'week'" class="subtitle-1"><br/>Semana {{currentWeek}}</span>
          </p>
          <!-- Botão Próximo -->
          <v-btn
              icon
              class="ma-1"
              @click="$refs.calendar.next()"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-sheet>
        <div>
          <v-select
              v-model="type"
              :items="[
          {text: 'Mensal', value: 'month'},
          {text: 'Semanal', value: 'week'},
          {text: 'Diário', value: 'day'},
        ]"
              dense
              outlined
              hide-details
              class="ma-2"
              label="Tipo de visualização"
          ></v-select>
        </div>
      </div>
      <v-calendar
          ref="calendar"
          v-model="selected"
          :now="today"
          :weekdays="[0, 1, 2, 3, 4, 5, 6]"
          :type="type"
          :events="events"
          color="info"
          :event-color="getEventColor"
          @click:event="showEvent"
      ></v-calendar>
    </v-card>
    <v-dialog v-model="showEventDialog" max-width="500">
      <v-card>
        <v-toolbar :color="colorValue ? colorValue : 'info'" flat>
          <v-toolbar-title class="white--text">{{colorName}}</v-toolbar-title>
          <v-spacer/>
          <v-toolbar-items>
            <v-btn icon @click="showEventDialog = false" dark>
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text class="pt-3 pb-2">
          <p class="title mb-0 text-center">{{dialogTitle}}</p>
          <p class="body-2 text-center mb-2" v-if="dialogFim">{{formatarData(dialogInicio)}}, até {{formatarData(dialogFim)}}</p>
          <p class="body-2 text-center mb-2" v-else>{{formatarData(dialogInicio)}}</p>
          <p class="text-center mb-2" v-if="dialogSala">
            Local reservado:
            <span v-if="dialogSala === 1">Sala de reunião</span>
            <span v-else-if="dialogSala === 2">Auditório do CRECI-MS</span>
          </p>
          <div v-if="dialogText">
            <v-divider class="mb-3"/>
            <p class="caption mb-0">Descrição:</p>
            <p class="text-justify mb-3 body-1" style="white-space: pre-wrap">{{dialogText}}</p>
          </div>
          <v-divider class="mb-2"/>
          <p class="caption mb-0" v-if="dialogAutor">Registrado por {{dialogAutor}}</p>
          <p class="caption mb-0" v-if="dialogPublico">Conteúdo marcado como público, está sendo exibido no site do CRECI.</p>
        </v-card-text>
        <v-card-actions class="justify-center" v-if="dialogEventId">
          <v-btn color="success" :to="'/calendario/evento/' + dialogEventId" :disabled="loading">Editar</v-btn>
          <v-btn color="error" @click="removerEvento(dialogEventId)" :loading="loading">Remover</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </async-container>
</template>

<script>
import AsyncContainer from '@/components/interface/AsyncContainer';
import moment from 'moment';
import SiteWebClient from '@/http/SiteWebClient';
export default {
  name: 'Index',
  components: {AsyncContainer},
  props: {
    'hideAddButton': {
      type: Boolean,
      default: false,
    }
  },
  data: () => ({
    loaded: false,
    type: 'month',
    selected: moment().format('YYYY-MM-DD'),
    webClient: new SiteWebClient(),
    today: moment().format('YYYY-MM-DD HH:mm:ss'),
    showEventDialog: false,
    compromissos: [],
    eventos: [],
    //Dialog
    colorName: '',
    colorValue: '',
    dialogTitle: '',
    dialogText: '',
    dialogSala: null,
    dialogInicio: '',
    dialogFim: '',
    dialogPublico: false,
    dialogAutor: '',
    dialogEventId: null, //Somente se for compromisso de calendario
    loading: false,
  }),
  computed: {
    currentDate() {
      return this.selected ? moment(this.selected).format(this.type === 'day' ? 'DD/MM/YYYY' : 'MMMM YYYY') : '';
    },
    currentWeek() {
      return this.selected ? moment(this.selected).format('ww') : '';
    },
    events() {
      const compromissos = this.compromissos.map(e => ({
        id: e.id,
        name: e.nome,
        start: e.inicio.substr(11, 5) === '00:00' ? e.inicio.substr(0,10) : e.inicio,
        end: e.fim,
        color: e.cor,
        timed: true, //e.publico,
      }));
      const eventos = this.eventos.map(e => ({
        id: 'e' + e.id,
        name: e.titulo,
        start: e.data_inicio,
        end: e.data_fim,
        color: 'warning',
        timed: true,
      }));
      return [...compromissos, ...eventos];
    },
  },
  methods: {
    async loadData() {
      try {
        this.compromissos = await this.webClient.getCalendarioEventos();
        this.eventos = await this.webClient.getEventos();
      } catch (e) {
        this.$router.push('/');
      } finally {
        this.loaded = true;
      }
    },
    getEventColor (event) {
      return event.color;
    },
    showEvent(e) {
      const isEvent = typeof e.event.id === 'string';
      if (isEvent) {
        const evento = this.eventos.find(i => i.id === parseInt(e.event.id.substr(1)));
        this.colorName = 'Evento';
        this.colorValue = 'warning';
        this.dialogTitle = evento.titulo;
        let text = '';
        if (evento.local) text += `Local: ${evento.local}\n`;
        if (evento.endereco) text += `Endereço: ${evento.endereco}\n`;
        if (evento.vagas) text += `Vagas: ${evento.vagas}\n`;
        if (evento.taxa) text += `Taxa: ${evento.taxa}\n`;
        this.dialogText = text;
        this.dialogInicio = evento.data_inicio;
        this.dialogFim = evento.data_fim;
        this.dialogPublico = true;
        this.dialogAutor = '';
        this.dialogEventId = null;
        this.dialogSala = null;
      } else {
        const compromisso = this.compromissos.find(i => i.id === e.event.id);
        this.colorName = compromisso.cor_nome;
        this.colorValue = compromisso.cor;
        this.dialogTitle = compromisso.nome;
        this.dialogText = compromisso.texto ? compromisso.texto : '';
        this.dialogSala = compromisso.sala;
        this.dialogInicio = compromisso.inicio;
        this.dialogFim = compromisso.fim;
        this.dialogPublico = compromisso.publico;
        this.dialogAutor = compromisso.autor_nome;
        this.dialogEventId = (this.$store.getters['session/payload'].user === compromisso.autor) ? compromisso.id : null;
      }
      this.showEventDialog = true;
    },
    formatarData(datetime) {
      return moment(datetime).format('DD/MM/YYYY HH:mm');
    },
    async removerEvento(id) {
      if (!confirm('Tem certeza que deseja apagar esse compromisso da agenda?')) return;
      this.loading = true;
      try {
        await this.webClient.deleteCalendarioEvento(id);
        await this.loadData();
        this.showEventDialog = false;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    setTimeout(() => this.loadData(), 1000);
  },
}
</script>

<style scoped>
  .title-bar {
    background-color: #f5f5f5;
    border-bottom: 1px solid #dbdbdb !important;
    padding: .75rem;
    margin-bottom: 0;
  }
</style>